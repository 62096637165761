@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Space Grotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Background layers */
.background-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
  overflow: hidden;
}

.stars {
  background: black url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/stars.png) repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.twinkling {
  width: 10000px;
  height: 100%;
  background: transparent url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/1231630/twinkling.png") repeat;
  background-size: 1000px 1000px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  /* Faster animation */
  -webkit-animation: move-background 40s linear infinite;
  -moz-animation: move-background 40s linear infinite;
  -o-animation: move-background 40s linear infinite;
  animation: move-background 40s linear infinite;
}

/* Animation for the background layers */
@keyframes move-background {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(1000px, 0, 0);
  }
}
